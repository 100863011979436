<template>
  <div class="col-lg-12 col-12">
    <div class="rounded shadow p-4">
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="mb-0">Métodos de pago:</h5>
        <div class="mb-1 row p-3">
          <!-- <div>
            <img
              src="images/payments/master-card.png"
              height="25"
              alt=""
              class="text-right"
            />
          </div>
          <div class="pl-3">
            <img
              src="images/payments/paypal.png"
              height="25"
              alt=""
              class="text-right"
            />
          </div>
          <div class="pl-3">
            <img
              src="images/payments/visa.png"
              height="25"
              alt=""
              class="text-right"
            />
          </div>
          <div class="pl-3">
            <img
              src="images/payments/american-ex.png"
              height="25"
              alt=""
              class="text-right"
            />
          </div> -->
        </div>
        <div class="mb-1">
          <div>
            <!-- <b-button class="btn btn-md btn-primary" @click="showModal()"
              >Añadir +</b-button
            > -->
            <b-modal
              ref="my-modal"
              size="lg"
              title="Selecciona tu método de pago"
              no-stacking
              ok-only
              hide-footer
            >
              <div class="row">
                <div class="master-id col-md-6 mt-4 pt-2">
                  <div
                    @click="setMaster()"
                    class="card rounded shadow bg-secondary border-0"
                  >
                    <div class="card-body">
                      <img
                        src="images/payments/costcoCards/master.png"
                        height="40"
                        alt=""
                        class="text-right"
                      />
                      <div class="mt-4">
                        <h5 class="text-light">•••• •••• •••• XXXX</h5>
                        <div class="d-flex justify-content-between">
                          <p class="h6 text-light mb-0">Cristino Murfy</p>
                          <h6 class="mb-0 text-light">
                            Exp: <span class="text-light">XX/XX</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="visa-id col-md-6 mt-4 pt-2">
                  <div
                    @click="setVisa()"
                    class="card rounded shadow bg-dark border-0"
                  >
                    <div class="card-body">
                      <img
                        src="images/payments/costcoCards/visaa.png"
                        height="40"
                        alt=""
                        class="text-right"
                      />
                      <div class="mt-4">
                        <h5 class="text-light">•••• •••• •••• XXXX</h5>
                        <div class="d-flex justify-content-between">
                          <p class="h6 text-muted mb-0">Calvin Carlo</p>
                          <h6 class="mb-0 text-muted">
                            Exp: <span class="text-muted">XX/XX</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="american-id col-md-6 mt-4 pt-2">
                  <div
                    @click="setAmerican()"
                    class="card rounded shadow bg-light border-0"
                  >
                    <div class="card-body">
                      <img
                        src="images/payments/costcoCards/americanEx.png"
                        height="80"
                        alt=""
                        class="text-right"
                      />
                      <div class="mt-1">
                        <h5 class="text-muted">•••• •••• •••• XXXX</h5>
                        <div class="d-flex justify-content-between">
                          <p class="h6 text-muted mb-0">Calvin Carlo</p>
                          <h6 class="mb-0 text-muted">
                            Exp: <span class="text-muted">XX/XX</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="paypal-id col-md-6 mt-4 pt-2">
                  <div
                    @click="setPaypal()"
                    class="card rounded shadow bg-light border-0"
                  >
                    <div class="card-body">
                      <img
                        src="images/payments/costcoCards/paypals.png"
                        height="40"
                        alt=""
                        class="text-right"
                      />
                      <div class="mt-4">
                        <form>
                          <div class="form-group mt-4 pt-3 mb-0">
                            <div class="input-group mt-4">
                              <input
                                name="email"
                                id="email"
                                type="email"
                                placeholder="Paypal Email :"
                                required="required"
                                class="form-control"
                              />
                              <div class="input-group-append">
                                <button
                                  type="submit"
                                  id="paypalmail"
                                  class="btn btn-primary submitBnt"
                                >
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body rounded mt-2">
      <div v-if="bankId === 'card'">
        <div class="row">
          <div class="col-2"></div>
          <div class="position-relative align-items-center col-md-8">
            <div class="card p-3 mb-5 bg-dark rounded border-0">
              <div class="card-body">
                <!-- <img
                  src="images/payments/costcoCards/master.png"
                  height="40"
                  alt=""
                  class="text-right"
                /> -->
                <div class="mt-2">
                  <div class="row">
                    <div class="col-8">
                      <label class="text-muted font-weight-normal" for=""
                        >Numero Tarjeta</label
                      >
                      <div v-if="form.number != ''">
                        <h5 class="text-light">
                          {{ cardNum($v.form.number.$model) }}
                        </h5>
                      </div>
                      <div v-else>
                        <h5 class="text-muted">#### #### #### ####</h5>
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="text-muted font-weight-normal" for=""
                        >codigo</label
                      >
                      <div v-if="form.cvc != ''">
                        <h5 class="text-light">
                          {{ $v.form.cvc.$model }}
                        </h5>
                      </div>
                      <div v-else>
                        <h5 class="text-muted">###</h5>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-8">
                      <label class="text-muted font-weight-normal" for=""
                        >Nombre Titular</label
                      >
                      <div v-if="form.cardholder != ''">
                        <h5 class="text-light">
                          {{ $v.form.cardholder.$model }}
                        </h5>
                      </div>
                      <div v-else>
                        <h5 class="text-muted">John Doe</h5>
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="fex text-muted font-weight-normal" for=""
                        >Fecha de Expiracion</label
                      >
                      <div v-if="form.month != '' || form.year != ''">
                        <h5 class="text-light">
                          {{ $v.form.month.$model }} / {{ $v.form.year.$model }}
                        </h5>
                      </div>
                      <div v-else>
                        <h5 class="text-muted">01/01</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row">
          <form
            id="formulario"
            class="col-md-8 mt-3 pt-3 text-primary container"
          >
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Titular:</label>
                  <div class="position-relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="fea icon-sm icons feather feather-user"
                    >
                      <path
                        d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                      ></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <input
                      v-model="$v.form.cardholder.$model"
                      id="cardholder"
                      type="text"
                      placeholder="Ingrese su nombre :"
                      class="form-control pl-5"
                      name="cardholder"
                    />
                    <div
                      class="invalid-feedback"
                      v-bind:class="{ 'd-block': $v.form.cardholder.$error }"
                    >
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Numero de tarjeta:</label>
                  <div class="position-relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="fea icon-sm icons feather feather-credit-card"
                    >
                      <rect
                        x="1"
                        y="4"
                        width="22"
                        height="16"
                        rx="2"
                        ry="2"
                      ></rect>
                      <line x1="1" y1="10" x2="23" y2="10"></line></svg
                    ><input
                      v-model="$v.form.number.$model"
                      name="number"
                      id="last"
                      type="text"
                      placeholder="Numero de tarjeta :"
                      class="form-control pl-5"
                      v-on:keypress="isNumber(event)"
                      maxlength="16"
                    />
                    <div
                      class="invalid-feedback"
                      v-bind:class="{ 'd-block': $v.form.number.$error }"
                    >
                      <span>ingrese el número de su tarjeta correctamente</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Mes de Expiración:</label>
                  <div class="position-relative">
                    <div>
                      <b-form-select
                        v-model="$v.form.month.$model"
                        :options="optionsMonth"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-select>
                    </div>
                    <div
                      class="invalid-feedback"
                      v-bind:class="{ 'd-block': $v.form.month.$error }"
                    >
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Año de Expiracion:</label>
                  <div class="position-relative">
                    <div>
                      <b-form-select
                        v-model="$v.form.year.$model"
                        :options="optionsYear"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-select>
                    </div>
                    <div
                      class="invalid-feedback"
                      v-bind:class="{ 'd-block': $v.form.year.$error }"
                    >
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>CVV:</label>
                  <div class="position-relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="fea icon-sm icons feather feather-credit-card"
                    >
                      <rect
                        x="1"
                        y="4"
                        width="22"
                        height="16"
                        rx="2"
                        ry="2"
                      ></rect>
                      <line x1="1" y1="10" x2="23" y2="10"></line></svg
                    ><input
                      v-model="$v.form.cvc.$model"
                      name="cvc"
                      id="cvc"
                      placeholder="Tu codigo :"
                      class="form-control pl-5"
                      maxlength="3"
                      v-on:keypress="isNumber(event)"
                    />
                    <div
                      class="invalid-feedback"
                      v-bind:class="{ 'd-block': $v.form.cvc.$error }"
                    >
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Cuotas:</label>
                  <div class="position-relative">
                    <div>
                      <b-form-select
                        v-model="$v.form.dues.$model"
                        :options="optionsDues"
                        value-field="value"
                        text-field="id"
                        disabled-field="notEnabled"
                      ></b-form-select>
                    </div>
                    <div
                      class="invalid-feedback"
                      v-bind:class="{ 'd-block': $v.form.dues.$error }"
                    >
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-12 text-center">
          <a-button
            @click="send()"
            class="btn col-md-6 btn-primary mt-4"
            v-bind:disabled="disabled"
          >
            <span
              v-if="disabled === true"
              class="mr-3 spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only"></span>
            Pagar
          </a-button>

          <b-toast id="Pago_exitoso_noty" variant="success" solid>
            <template #toast-title>
              <div class="d-flex flex-grow-1 align-items-baseline">
                <b-img
                  blank
                  blank-color="#B0F680"
                  class="mr-2"
                  width="12"
                  height="12"
                ></b-img>
                <strong class="mr-auto"></strong>
                <small class="text-muted mr-2">Confirmación de pago</small>
              </div>
            </template>
            <div class="container text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check-circle mt-5"
              >
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                <polyline points="22 4 12 14.01 9 11.01"></polyline>
              </svg>
              <h4 class="mb-5">PAGO EXITOSO !!</h4>
            </div>
          </b-toast>

          <b-toast id="Pago_error_noty" variant="danger" solid>
            <template #toast-title>
              <div class="d-flex flex-grow-1 align-items-baseline">
                <b-img
                  blank
                  blank-color="#ff5555"
                  class="mr-2"
                  width="12"
                  height="12"
                ></b-img>
                <strong class="mr-auto"></strong>
                <small class="text-muted mr-2">Error de pago</small>
              </div>
            </template>
            <div class="container text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x-octagon mt-5"
              >
                <polygon
                  points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"
                ></polygon>
                <line x1="15" y1="9" x2="9" y2="15"></line>
                <line x1="9" y1="9" x2="15" y2="15"></line>
              </svg>
              <h4 class="mb-5">HA OCURRIDO UN PROBLEMA !!</h4>
            </div>
          </b-toast>
        </div>
      </div>
    </div>
  </div>
</template>

 

<script>
import { validationMixin } from "vuelidate";
import { required, email, numeric } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  mixins: [validationMixin],
  data() {
    return {
      disabled: false,
      bankId: "card",
      form: {
        cardholder: "",
        number: "",
        month: "",
        year: "",
        cvc: "",
        dues: "",
      },

      year: "1",
      optionsYear: [],
      month: "1",
      optionsMonth: [],
      dues: "1",
      optionsDues: [
        { value: 1, id: "1" },
        // {value: 2, id: "2"},
        { value: 3, id: "3" },
        // {value: 4, id: "4"},
        // {value: 5, id: "5"},
        { value: 6, id: "6" },
        // {value: 7, id: "7"},
        { value: 12, id: "12" },
        // {value: 7, id: "7"},
        { value: 24, id: "24" },
      ],
    };
  },

  validations: {
    form: {
      cardholder: { required },
      number: { required },
      month: { required, numeric },
      year: { required, numeric },
      cvc: { required, numeric },
      dues: { required, numeric },
    },
  },

  methods: {
    cardNum(str) {
      var long = str.length;
      var blankCount = 0;

      if (long <= 4) {
        blankCount = 0;
      } else {
        blankCount = long % 4 > 0 ? long / 4 : str.length / 4 - 1;
      }

      if (blankCount > 0) {
        for (var i = 0; i < blankCount; i++) {
          str =
            str.substring(0, (i + 1) * 4 + i) +
            " " +
            str.substring((i + 1) * 4 + i);
          // console.log(str);
        }
      } else {
        console.log(
          "La cadena de entrada no tiene más de 4 dígitos, no es necesario agregar espacios"
        );
      }
      // Volver

      return str;
    },

    send() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      if (this.form.number.length === 16) {
        this.disabled = !this.disabled;
        this.cardService();
      } else {
        this.form.number = "";
      }
    },

    cardService() {
      var Base_URL = `https://us-central1-dtc-2012.cloudfunctions.net`;
      axios
        .post(`${Base_URL}/AIG_D2C_PAYMENT_EMILI_PROCESS_PAYMENT_V2`, {
          ccs: this.form.cvc,
          anioExpiracion: this.form.year,
          conductoPago: 0,
          cuenta: this.form.number,
          idBanco: 0,
          idPlazo: this.form.dues,
          mesExpiracion: this.form.month,
          titular: this.form.cardholder,
          Username: "hilda.garcia",
          currency: "MXN",
          monto: 300.94,
          referencia: "l8FHHU19950913-92",
        })
        .then((response) => {
          console.log(response.status);

          if (response.status != 200) {
            this.$bvToast.show("Pago_exitoso_noty");
          } else {
            this.$bvToast.show("Pago_error_noty");
          }

          this.disabled = !this.disabled;
        });
    },

    selectYear() {
      for (let i = 2021; i <= 2050; i++) {
        this.optionsYear.push(i);
      }
    },

    selectMonth() {
      for (let i = 1; i <= 12; i++) {
        this.optionsMonth.push(i);
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  mounted() {
    this.selectYear();
    this.selectMonth();
  },
};
</script>

<style>
.rowing {
  border: 1px solid black;
}

.fex {
  font-size: 13px;
}

.card {
  -webkit-box-shadow: 13px 9px 18px -1px rgba(99, 99, 101, 0.4);
  box-shadow: 13px 9px 18px -1px rgba(97, 98, 99, 0.4);
}
</style>